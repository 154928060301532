<template>
  <div>
    <Header />
    <div style="margin-top: 20px">
      <div class="topTitle">
        分会场列表
      </div>
      <div class="topTabs">
        <div v-if="meetShow">
          <el-button type="primary" @click="newMeet">新建分会场</el-button>
        </div>
        <div style="margin-left: auto;margin-right: 0">
          <el-tabs class="tabClas" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="进行中" name="0"></el-tab-pane>
            <el-tab-pane label="已结束" name="1"></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div style="width: 40%;height: 1px;border-top: 1.5px solid #ccc;margin: 0 auto;margin-bottom: 10px"></div>
      <div class="outBlock" v-for="(item,index) in listArr" :key="index" @click="meetClick(item)">
        <div class="firBlo" v-if="item.picture">
          <el-image :src=url+item.picture></el-image>
        </div>
        <div class="firBlo" v-else>
          <el-image :src="require('@/assets/nopic.jpg')"></el-image>
        </div>
        <div class="botBlo">
          <div class="titBlo">{{ item.name }}</div>
          <div class="dataBlo">
            {{ item.conferenceTime }}
          </div>
        </div>
      </div>
    </div>


    <el-pagination @current-change="handleCurrentChange" background :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
    </el-pagination>

    <el-dialog title="新建分会场" :visible.sync="AdddialogVisible" width="410px" :before-close="AdddialogVisibleClose">
      <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="100px">
        <el-form-item label="会议名称：" prop="name">
          <el-input v-model="AddDateModel.name" placeholder="请填写会议名称"></el-input>
        </el-form-item>

        <el-form-item label="会议封面：" prop="picture">
          <el-upload class="avatar-uploader" ref="otherLicense"
                     action
                     :auto-upload="false"
                     :on-remove="handleRemove"
                     :file-list="pict"
                     :class="{ hide: hideUploadBtn }"
                     :limit="1"
                     :on-change="otherSectionFile"
                     list-type="picture-card" multiple>
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="会议地点：" prop="address">
          <el-input v-model="AddDateModel.address" placeholder="请填写会议地点"></el-input>
        </el-form-item>

        <el-form-item label="会议时间：" prop="conferenceTime">
          <el-date-picker v-model="AddDateModel.conferenceTime"
                          value-format="yyyy-MM-dd" type="date" placeholder="请选择会议时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="主办单位:" prop="sponsorList">
          <el-row v-for="(item,i) in sponsorList" :key="i">
            <el-input type="text" v-model="AddDateModel.sponsorList[i]" placeholder="请输入主办单位">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addSponsorList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subSponsorList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item label="协办单位:" prop="assistList">
          <el-row v-for="(item,i) in assistList" :key="i">
            <el-input type="text" v-model="AddDateModel.assistList[i]" placeholder="请输入协办单位">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addAssistList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subAssistList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item label="承办单位:" prop="organizerList">
          <el-row v-for="(item,i) in organizerList" :key="i">
            <el-input type="text" v-model="AddDateModel.organizerList[i]" placeholder="请输入承办单位">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addOrganizerList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subOrganizerList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item label="指导单位:" prop="guidanceList">
          <el-row v-for="(item,i) in guidanceList" :key="i">
            <el-input type="text" v-model="AddDateModel.guidanceList[i]" placeholder="请输入指导单位">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addGuidanceList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subGuidanceList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item label="合作媒体:" prop="mediaList">
          <el-row v-for="(item,i) in mediaList" :key="i">
            <el-input type="text" v-model="AddDateModel.mediaList[i]" placeholder="请输入合作媒体">
              <template slot="append">
                <el-button circle icon="el-icon-plus" @click="addMediaList()"></el-button>
                <el-button circle icon="el-icon-minus" @click="subMediaList(i)" v-if="i>0"></el-button>
              </template>
            </el-input>
          </el-row>
        </el-form-item>

        <el-form-item class="depar-dia btnCen">
          <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">添加</el-button>
          <el-button v-else type="info" :loading="addDepClickKing"></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import _qs from "qs";
import Vue from "vue";
import Header from "@/components/Header/index.vue";
import sunburstSeries from "echarts/src/chart/sunburst/SunburstSeries";
export default {
    data() {
      // let validateImage = (rule, value, callback) => {
      //   //验证器
      //   if (!this.AddDateModel.picture) {
      //     //为true代表图片在  false报错
      //     callback(new Error("请上传会议海报"));
      //   } else {
      //     callback();
      //   }
      // };
        return {
          activeName:'0',
          roleId:window.sessionStorage.getItem("roleId"),
          addDepClick: true,
          addDepClickKing: false,
          listArr:[],
          // 当前页数
          pageNum: 1,
          // 每页显示条数
          pageSize: 10,
          // 总数
          total: 1,
          url:'',
          pict:[],
          AddDateModel: {
            name: "",
            picture:'',
            address:'',
            conferenceTime:'',
            sponsorList: [''],
            assistList: [''],
            organizerList: [''],
            guidanceList: [''],
            mediaList: ['']
          },
          AddDateRules: {
            name: [
              { required: true, message: "请填写会议名称", trigger: "blur" },
            ],
            // picture:[
            //   {
            //     required: true,
            //     validator: validateImage,
            //     trigger: "blur",
            //   },
            // ]
          },
          AdddialogVisible: false,
          hideUploadBtn:false,
          meetShow:false,
          sponsorList: [''],
          assistList: [''],
          organizerList: [''],
          guidanceList: [''],
          mediaList: [''],
        };
    },
    created() {
      if(this.roleId.includes('2')){
        this.meetShow=true
      }
      this.pageList()
    },
    components: {
      Header
    },
    methods: {
      sunburstSeries,
      //加号
      addSponsorList() {
        this.sponsorList.push('')
      },
      //减号
      subSponsorList(index) {
        this.sponsorList.splice(index, 1)
      },

      //加号
      addAssistList() {
        this.assistList.push('')
      },
      //减号
      subAssistList(index) {
        this.assistList.splice(index, 1)
      },

      //加号
      addOrganizerList() {
        this.organizerList.push('')
      },
      //减号
      subOrganizerList(index) {
        this.organizerList.splice(index, 1)
      },

      //加号
      addGuidanceList() {
        this.guidanceList.push('')
      },
      //减号
      subGuidanceList(index) {
        this.guidanceList.splice(index, 1)
      },

      //加号
      addMediaList() {
        this.mediaList.push('')
      },
      //减号
      subMediaList(index) {
        this.mediaList.splice(index, 1)
      },
      addDays() {
        this.courseInfo.daysArray.push({});
      },
      delDays(index){
        this.courseInfo.daysArray.splice(index, 1);
      },
      handleClick(tab, event) {
        this.pageNum = 1;
        this.pageList()
      },
      handleRemove(file,fileList) {
        this.hideUploadBtn = fileList.length >= 1;
      },
      otherSectionFile(file,fileList) {
        // console.log(file,fileList)
        this.hideUploadBtn = fileList.length >= 1;
        const typeArr = [
          "image/png",
          "image/gif",
          "image/jpeg",
          "image/jpg",
        ];
        const isJPG = typeArr.indexOf(file.raw.type) !== -1;
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isJPG) {
          this.$message.error("只能是图片!");
          this.$refs.upload.clearFiles();
          this.otherFiles = null;
          return;
        }
        if (!isLt20M) {
          this.$message.error("上传图片大小不能超过 20MB!");
          this.$refs.upload.clearFiles();
          this.otherFiles = null;
          return;
        }
        this.otherFiles = file.raw;
        var formData = new FormData();
        formData.append("conferencePosters", this.otherFiles);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          methods: "post",
        };
        this.$axios.uploadPosters(formData, config).then((res) => {
          // console.log(res)
          // console.log(res.data.msg)
          if(res.data.code==200){
            this.AddDateModel.picture=res.data.msg
          }
        });
      },
      parmen() {
        this.$refs.AddDateRef.validate(async(valid) => {
          if (valid) {
            this.addDepClick = false;
            this.addDepClickKing = true;
            let data = _qs.stringify({
              name: this.AddDateModel.name,
              picture:this.AddDateModel.picture,
              address:this.AddDateModel.address,
              meetingId:window.sessionStorage.getItem('meetingId'),
              conferenceTime:this.AddDateModel.conferenceTime,
              sponsor:this.AddDateModel.sponsorList.join(","),
              assist:this.AddDateModel.assistList.join(","),
              organizer:this.AddDateModel.organizerList.join(","),
              guidance:this.AddDateModel.guidanceList.join(","),
              media:this.AddDateModel.mediaList.join(",")
            });
            let res = await this.$axios.saveConference(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            if (res.data.code == 401) {
              this.$router.push("/login");
            } else if (res.status == 200) {
              this.AdddialogVisibleClose();
              this.pageList();
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          }
        });
      },
      newMeet(){
        this.AdddialogVisible = true;
      },
      // 点击添加科室的时候取消正则
      AdddialogVisibleClose() {
        this.addName = "添加会议";
        this.btnText = "添加";
        this.reayName = "";
        this.AdddialogVisible = false;
        this.$refs.AddDateRef.resetFields();
        this.sponsorList.splice(1);
        this.assistList.splice(1);
        this.organizerList.splice(1);
        this.guidanceList.splice(1);
        this.mediaList.splice(1);
        this.AddDateModel = {
          name: "",
          picture:'',
          sponsorList: [''],
          assistList: [''],
          organizerList: [''],
          guidanceList: [''],
          mediaList: ['']
        };
        this.hideUploadBtn=false
        this.pict=[]
      },
      async meetClick(val){
        // 查询用户在分会场中的角色
        let data = _qs.stringify({
          conferenceId:val.id,//会场id
          userId:window.sessionStorage.getItem("userId")
        });
        let { data: res } = await this.$axios.findVenueRole(data);
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 200) {
          window.sessionStorage.setItem("conferenceId", val.id);
          window.sessionStorage.setItem("venueRole", res.data);
          // 会议管理员 会场管理员
          if(res.data === 2 || res.data === 3){
            this.$router.push("/board");
          }
          // 任务负责人
          else{
            this.$router.push("/toAccept");
          }
        }else{
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      },
      async pageList(){
        let data = _qs.stringify({
          page:this.pageNum,//页
          size:this.pageSize,//条
          searchParams:'',// 名称
          status:this.activeName,// 0 进行中 1 已结束
          meetingId:window.sessionStorage.getItem('meetingId') // 会议id
        });
        let { data: res } = await this.$axios.conferList(
            data
        );
        // console.log(res.data);
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 200) {
          this.listArr=res.data.records
          this.total=res.data.total
          this.url=Vue.prototype.GLOBAL
        }else{
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.pageList();
      },
    },
};
</script>

<style lang="less" scoped>

.outBlock:hover{
  cursor: pointer;
}

.topTabs{
  width: 40%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topTitle{
  width: 40%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

  .outBlock{
    width: 38%;
    background: white;
    display: flex;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-shadow: 0 0 16px #ccc;
    //border-radius: 20px 0 20px 20px;
    margin: 0 auto;
    margin-bottom: 20px;
    /deep/ .firBlo .el-image img{
      width: 250px;
      height: 140px;
      object-fit: cover!important;
      //border-radius: 20px;
    }
    /deep/ .firBlo .el-image__error{
      width: 250px;
      height: 140px;
    }
    .botBlo{
      margin-left: 10px;
      .titBlo{
        font-size: 20px;
        font-weight: bold;
      }
      .dataBlo{
        color: #6e6767;
        margin-top: 5px;
      }
    }
  }

  .el-pagination {
    text-align: center;
  }

  /deep/ .el-dialog__body {
    text-align: inherit;
    .btnCen {
      button {
        width: 150px;
      }
    }
  }

  /deep/ .el-input__inner{
    width: 200px;
  }

  .hide {
    /deep/.el-upload--picture-card {
      display: none !important;
    }
  }

  /deep/ .tabClas .el-tabs__item{
    padding: 0 10px!important;
  }
</style>
